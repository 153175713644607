@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


html,body{
    margin: 0;
    padding: 0;
}
.slider {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: relative;
   }
    
   .slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation: slider 19.5s infinite;
   }
    
   .slider .slide:nth-child(1) {
      background-image: url('image1.jpg');
      animation-delay: 0s;
   }
   
   .slider .slide:nth-child(2) {
      background-image: url('image1.jpg');
      animation-delay: -3.9s;
   }
   
   .slider .slide:nth-child(3) {
      background-image: url('image3.jpg');
      animation-delay: -7.8s;
   }
   
   .slider .slide:nth-child(4) {
      background-image: url('image1.jpg');
      animation-delay: -11.7s;
   }
   
   .slider .slide:nth-child(5) {
      background-image: url('image2.jpg');
      animation-delay: -15.6s;
   }
   
   .container{
    position: absolute;  
    top: 0;
    left: 0; 
    width: 100%;
    height: 100vh;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
}

.container .row{
    display: flex;
    align-items: center;
    justify-content: center;

}

.container h1{
    font-size: 60px;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.container .row b{
    margin-left: 10px;
    font-size: 95px;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.container .row span{
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.logo{
  width: 95px;
}


@media (max-width: 768px) {
  .container h1{font-size: 30px;}
  .container .row b{font-size: 50px;}
  .logo{width: 47px;}
}

   
   @keyframes slider {
     0%, 15%, 100% {
       opacity: 1;
       animation-timing-function: ease;
       z-index: 0;
     }
     20% {
       opacity: 0;
       animation-timing-function: step-end;
       z-index: 0;
     }
     95% {
       opacity: 1;
       animation-timing-function: step-end;
       z-index: -1;
     }
   }